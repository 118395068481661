<template>
  <nav class="w-full fixed mx-auto inset-x-0 z-40" :class="isScrolled">
    <div class="p-4 w-full xl:max-w-[1320px] 2xl:max-w-[1380px] mx-auto">
      <div class="flex justify-between items-center">
        <a @click="goTo('home')" class="cursor-pointer">
          <img :src="isInverted ? '/img/logo-black.svg' : '/img/logo-white.svg'" alt="Logo">
        </a>

        <div class="hidden md:flex gap-x-10 text-lg" :class="isInverted ? 'text-brand-black' : 'text-white'">
          <div class="cursor-pointer relative"
               @mouseover="showProductsModal = true"
               @mouseleave="showProductsModal = false"
          >
            <div class="flex items-center gap-1">
              Products

              <img src="../assets/down.svg"/>
            </div>

            <div
                v-if="showProductsModal"
                class="text-lg flex flex-col p-2  gap-1.5 absolute left-0 z-10 mt-0 w-48 bg-white border border-gray-200 rounded-md shadow-lg"
                @mouseenter="showProductsModal = true"
                @mouseleave="showProducts = false"
            >
              <a @click="goTo('products-airis')" class="hover:underline text-brand-black">Platform <span class="text-brand-green">Airis</span></a>
              <a @click="goTo('products-airis-lite')" class="hover:underline text-brand-black">Platform <span class="text-brand-green">Airis Flite</span></a>
            </div>
          </div>
          <div @click="goTo('solutions')" class="cursor-pointer">Solutions</div>
          <div @click="goTo('technology')" class="cursor-pointer">Technology</div>
          <div @click="goTo('company')" class="cursor-pointer">Company</div>
        </div>

        <div class="hidden md:block">
          <button
              @click="openContactForm"
              class="px-5 py-2 rounded-2xl font-medium"
              :class="isInverted ? 'bg-black text-white' : 'bg-white text-brand-black'"
          >Become a Partner</button>
        </div>

        <img v-if="!showMenu" @click="showMenu = true" :src="isInverted ? '/img/menu-black.svg' : '/img/menu.svg'" class="md:hidden cursor-pointer" alt="menu"/>
      </div>
    </div>

    <!-- Mobile menu -->
    <div v-if="showMenu" class="w-full h-full fixed top-0 bg-black z-50 p-4 text-white">
      <div class="flex flex-col justify-between h-full">
        <div class="flex items-center justify-between">
          <img src="../assets/logo-white.svg" alt="Logo" @click="goTo('home')">
          <img @click="showMenu = false" src="../assets/close.svg" class="md:hidden cursor-pointer" alt="menu"/>
        </div>

        <div>
          <div class="mb-4">
            <a class="flex gap-2 cursor-pointer" @click="showProducts =! showProducts">
              <p class="text-3xl">Products</p>
              <img :src="showProducts ? '/img/up.svg' : '/img/down.svg'" alt="down">
            </a>

            <div v-if="showProducts" class="text-lg flex flex-col gap-1.5 mt-4">
              <a @click="goTo('products-airis')">Platform <span class="text-brand-green">Airis</span></a>
              <a @click="goTo('products-airis-lite')">Platform <span class="text-brand-green">Airis Flite</span></a>
            </div>
          </div>

          <div class="mb-4">
            <div class="flex gap-2 cursor-pointer" @click="showSolutions =! showSolutions">
              <p class="text-3xl">Solutions</p>
              <img :src="showSolutions ? '/img/up.svg' : '/img/down.svg'" alt="down">
            </div>

            <div v-if="showSolutions" class="text-lg flex flex-col gap-1.5 mt-4">
              <a @click="goTo('solutions', 'solutions')">Enterprises</a>
              <a @click="goTo('solutions', 'solutions')">Businesses</a>
              <a @click="goTo('solutions', 'solutions')">Governments</a>
              <a @click="goTo('solutions', 'solutions')">Home delivery</a>
            </div>
          </div>

          <div class="mb-4">
            <a @click="goTo('technology')" class="text-3xl">Technology</a>
          </div>

          <div>
            <a @click="goTo('company')" class="text-3xl">Company</a>
          </div>
        </div>

        <div class="grid grid-cols-3 gap-2 font-medium">
          <a>LinkedIn</a>
          <a>Facebook</a>
          <a>Instagram</a>
          <a>Twitter</a>
          <a>Youtube</a>
        </div>
      </div>
      <img src="../assets/symbol.svg" class="absolute right-0 -z-20 top-1/2 -translate-y-1/2" alt="symbol"/>
    </div>
  </nav>
</template>

<script>
import ContactForm from "@/components/ContactForm.vue";

export default {
  name: "Navbar",
  components: {ContactForm},
  data: () => ({
    showProducts: false,
    showSolutions: false,
    showMenu: false,
    showProductsModal: false,
    scrolled: false
  }),
  mounted() {
    window.addEventListener('scroll', this.onScroll)
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.onScroll)
  },
  computed: {
    isInverted() {
      return this.$route.name !== 'home'
    },
    isScrolled() {
      let color = this.isInverted ? 'bg-white' : 'bg-black'
      return this.scrolled ? color : 'bg-transparent'
    }
  },
  methods: {
    onScroll() {
      this.scrolled = window.scrollY > 50;
    },
    goTo(name, hash = null) {
      this.showMenu = false
      if (hash) {
        this.$router.push({name: name, hash: '#' + hash})
      }else {
        this.$router.push({name: name})
      }
    },
    openContactForm() {
      this.$store.dispatch('showContactForm', true)
    }
  }
}
</script>