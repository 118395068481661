<template>
  <div v-if="isVisible" class="w-full flex items-center justify-center fixed z-50 inset-0 bg-black">
    <div class="max-w-sm w-full rounded-2xl bg-white p-6 relative">
      <p class="text-2xl font-medium text-center mb-6">Contact Us</p>

      <div class="absolute right-3 top-16 cursor-pointer" @click="closeContactForm">
        <img src="../assets/close2.svg" alt="close">
      </div>

      <div>
        <div class="mb-5">
          <p class="text-brand-gray-secondary5 mb-1">Email <span class="text-red-500">*</span></p>
          <input v-model="form.email" class="block h-[56px] p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500" placeholder="Enter your email"/>
        </div>

        <div class="mb-5">
          <p class="text-brand-gray-secondary5 mb-1">Subject <span class="text-red-500">*</span></p>
          <input v-model="form.subject" class="block h-[56px] p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500" placeholder="Title of your request"/>
        </div>

        <div class="mb-8">
          <p class="text-brand-gray-secondary5 mb-1">Message</p>
          <textarea v-model="form.message" id="message" rows="6" class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500" placeholder="Write your thoughts here..."></textarea>
        </div>

        <button @click="sendMessage"
                class="flex items-center justify-center bg-brand-green rounded-lg w-full p-3 text-white"
                :class="loading ? 'bg-opacity-30 cursor-not-allowed' : ''"
                :disabled="loading"
        >
          Submit
          <img src="../assets/right.svg" class="ml-2" alt="right">
        </button>
      </div>
    </div>
  </div>
</template>

<script>
  import emailjs from "emailjs-com"
  import {mapGetters} from "vuex";
  import { useToast } from 'vue-toastification'

  export default {
    name: 'ContactForm',
    data: () => ({
      form: {
        email: null,
        subject: null,
        message: null
      },
      loading: false
    }),
    computed: {
      ...mapGetters({
        'isVisible': 'contactForm'
      })
    },
    methods: {
      closeContactForm() {
        this.$store.dispatch('showContactForm', false)
      },
      sendMessage(){
        this.loading = true
        const serviceID = "service_eox7ol5"; // Replace with your EmailJS service ID
        const templateID = "template_k6b2xqc"; // Replace with your EmailJS template ID
        const userID = "TL6U_58-G1jauf1uY";

        emailjs.send(
            serviceID,
            templateID,
            {
              from_name: 'From Arc Site',
              from_email: this.form.email,
              message: this.form.message
            },
            userID
        ).then(() => {
          this.loading = false
          this.closeContactForm()
          useToast().success('Email successfully sent')
        }).finally(() => { this.loading = false })
      }
    }
  }
</script>