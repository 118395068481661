<template>
  <div>
    <!-- Hero -->
    <div class="h-screen w-full bg-center relative overflow-hidden">
      <div class="bg-black w-full h-full bg-opacity-50 flex justify-center items-center">
        <div class="text-center max-w-2xl">
          <p class="uppercase text-gray-200 mb-3 tracking-[.20em] text-xs md:text-lg">Drone Delivery</p>
          <h1 class="font-medium tracking-wide text-white text-3xl md:text-7xl mb-6 w-72 md:w-full mx-auto">Soaring high ahead of time</h1>
          <p class="text-white md:text-2xl w-80 md:max-w-md md:w-full mx-auto mb-6">
            Autonomous, sustainable, and cost-effective solution for last-mile delivery
          </p>
          <button @click="openContactForm" class="bg-brand-green px-8 md:text-lg font-medium py-3 text-white rounded-3xl">Get Delivery</button>
        </div>
      </div>

      <video autoplay playsinline muted loop preload="" class="min-w-full min-h-full max-w-none object-cover object-center absolute inset-0 -z-10">
        <source src="../assets/hero.mp4" type="video/mp4"/>
      </video>
    </div>

    <!-- Partners -->
    <div class="xl:max-w-[1320px] 2xl:max-w-[1380px] w-full mx-auto px-4 py-12 flex space-x-16 overflow-hidden">
      <div class="flex space-x-16 animate-loop-scroll">
        <img src="../assets/partner1.svg" alt="partner" class="max-w-none"/>
        <img src="../assets/partner2.svg" alt="partner" class="max-w-none"/>
        <img src="../assets/partner3.svg" alt="partner" class="max-w-none"/>
        <img src="../assets/partner4.svg" alt="partner" class="max-w-none"/>
        <img src="../assets/partner5.svg" alt="partner" class="max-w-none"/>
        <img src="../assets/partner6.svg" alt="partner" class="max-w-none"/>
        <img src="../assets/partner7.svg" alt="partner" class="max-w-none"/>
      </div>

      <div class="flex space-x-16 animate-loop-scroll" aria-hidden="true">
        <img src="../assets/partner1.svg" alt="partner" class="max-w-none"/>
        <img src="../assets/partner2.svg" alt="partner" class="max-w-none"/>
        <img src="../assets/partner3.svg" alt="partner" class="max-w-none"/>
        <img src="../assets/partner4.svg" alt="partner" class="max-w-none"/>
        <img src="../assets/partner5.svg" alt="partner" class="max-w-none"/>
        <img src="../assets/partner6.svg" alt="partner" class="max-w-none"/>
        <img src="../assets/partner7.svg" alt="partner" class="max-w-none"/>
      </div>
    </div>

    <!-- Explore -->
    <div class="xl:max-w-[1320px] 2xl:max-w-[1380px] mx-auto py-12 md:py-24 rounded-2xl bg-gradient-to-b from-[#FFFADF] to-[#F4FAF400] relative">
      <div class="relative">
        <img src="../assets/line.png" class="absolute -z-20 top-1/3"/>

        <h3 class="text-center text-4xl w-64 mx-auto md:w-full md:text-6xl mb-8 tracking-wide">Explore our products</h3>

        <div class="py-4 w-full md:max-w-sm mx-auto text-center flex justify-between no-scrollbar overflow-auto gap-8 px-4 md:px-0 flex-grow mb-24 text-xl relative h-16">
          <div
              v-for="slide in slides"
              :key="slide.id"
              :class="slide.id === currentSlide ? 'text-brand-black' : 'text-brand-gray-secondary7'"
              @click="moveSlide(slide.id)"
              class="cursor-pointer w-auto flex-shrink-0 tracking-widest"
          >
            {{slide.name}}
          </div>
        </div>

        <div class="w-full relative h-[800px] overflow-hidden">
          <div
              v-for="slide in slides"
              :key="slide.id"
              :id="slide.id"
              class="text-center bg-transparent absolute top-0 inset-x-0 transform transition-all duration-1000 ease-in-out ss"
              :class="1 === slide.id ? 'translate-x-0' : 'translate-x-full'"
          >
            <img :src="'/img/'+slide.image" alt="drone" class="mx-auto px-4 w-full max-w-[700px] mb-16"/>

            <div v-html="slide.description"></div>

            <button
                @click="goTo(slide.url)"
                class="hidden md:block mx-auto font-medium rounded-3xl hover:bg-brand-black hover:text-white border border-brand-black px-6 py-2 mb-16">
              Learn more
            </button>

            <div class="max-w-4xl grid grid-cols-2 md:grid-cols-4 gap-8 px-4 mx-auto">
              <div>
                <p class="uppercase text-xs md:text-base text-brand-gray-secondary6">Flight distance</p>
                <div class="flex items-start justify-center font-medium">
                  <span class="text-4xl md:text-5xl mr-1">{{slide.stat.distance}}</span>
                  <span class="uppercase">km</span>
                </div>
              </div>

              <div>
                <p class="uppercase text-xs md:text-base text-brand-gray-secondary6">Max payload weight</p>
                <div class="flex items-start justify-center font-medium">
                  <span class="text-4xl md:text-5xl mr-1">{{slide.stat.weight}}</span>
                  <span class="uppercase">kg</span>
                </div>
              </div>

              <div>
                <p class="uppercase text-xs md:text-base text-brand-gray-secondary6">Max speed</p>
                <div class="flex items-start justify-center font-medium">
                  <span class="text-4xl md:text-5xl mr-1">{{slide.stat.speed}}</span>
                  <span class="uppercase">m/s</span>
                </div>
              </div>

              <div>
                <p class="uppercase text-xs md:text-base text-brand-gray-secondary6">Flight Time</p>
                <div class="flex items-start justify-center font-medium">
                  <span class="text-4xl md:text-5xl mr-1">{{slide.stat.time}}</span>
                  <span class="uppercase">{{slide.stat.timeM}}</span>
                </div>
              </div>
            </div>

            <button class="md:hidden mt-12 mx-auto font-medium rounded-3xl hover:bg-brand-black hover:text-white border border-brand-black px-6 py-2 mb-16">
              Learn more
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- Better solution -->
    <div class="xl:max-w-[1320px] 2xl:max-w-[1380px] mx-auto px-4 py-12">
      <div class="text-center mb-16">
        <h3 class="font-medium text-3xl md:text-5xl mb-3 w-64 mx-auto md:w-full">Better solution for more people</h3>
        <p class="text-brand-gray-secondary max-w-xl md:text-lg mx-auto">We aim to automate every aspect associated with delivery logistics,
          creating a cohesive system that helps solve challenges.
        </p>
      </div>

      <div class="flex flex-col md:flex-row items-center gap-8">
        <div class="w-full md:w-1/2 flex flex-col gap-8">
          <div class="bg-brand-green-tonal rounded-2xl p-4 md:p-12 relative overflow-hidden md:h-96 lg:h-[512px] transform
                                        transition duration-1000
                                        hover:scale-95">
            <p class="text-brand-green font-medium text-2xl md:text-4xl mb-4">Delivery</p>
            <p class="mb-5 max-w-md text-brand-gray-secondary2 text-xs md:text-lg leading-6">We develop courier drones for delivering food, medication, and parcels.
              Our inventory includes drones for medical delivery, food delivery, and  heavy-lifting drones.
            </p>
            <button @click="goTo('solutions', 'delivery')" class="font-medium text-xs md:text-base text-brand-green hover:bg-brand-green hover:text-white cursor-pointer rounded-3xl border border-brand-green px-6 py-2">
              Read more
            </button>

            <img src="../assets/r1.svg" alt="r1" class="absolute right-0 bottom-0 -z-10">
          </div>

          <div class="bg-brand-gray-tonal rounded-2xl p-8 relative overflow-hidden md:h-80 lg:h-[376px] transform
                                        transition duration-1000
                                        hover:scale-95">
            <p class="font-medium text-2xl md:text-4xl mb-4">Education/Training</p>
            <p class="mb-5 max-w-lg text-brand-gray-secondary2 text-xs md:text-lg leading-6">Gain the skills and knowledge needed to effectively manage
              a drone mini-airport with our comprehensive training program.
            </p>
            <button @click="goTo('solutions', 'education')" class="font-medium text-xs md:text-base rounded-3xl border border-brand-black hover:bg-brand-black hover:text-white px-6 py-2">
              Read more
            </button>

            <img src="../assets/r2.svg" alt="r1" class="absolute right-0 bottom-0 -z-10">
          </div>
        </div>

        <div class="w-full md:w-1/2 flex flex-col gap-8 lg:gap-10">
          <div class="bg-brand-blue-tonal rounded-2xl p-8 relative overflow-hidden md:h-88 lg:h-[440px] transform
                                        transition duration-1000
                                        hover:scale-95">
            <p class="text-brand-blue font-medium text-2xl md:text-4xl mb-4">Fleet management</p>
            <p class="mb-5 max-w-lg text-brand-gray-secondary2 text-xs md:text-lg leading-6">Unlock the full potential of your drone fleet with our comprehensive management solution,
              providing real-time monitoring, analytics, and automation.
            </p>
            <button @click="goTo('solutions', 'fleet')" class="font-medium text-xs md:text-base text-brand-blue hover:bg-brand-blue hover:text-white rounded-3xl border border-brand-blue px-6 py-2">
              Read more
            </button>

            <img src="../assets/r3.svg" alt="r1" class="absolute -z-10 -right-1/2 -bottom-1/2 md:right-0 md:-bottom-4">
          </div>

          <div class="bg-brand-orange-tonal rounded-2xl p-8 relative overflow-hidden md:h-88 lg:h-[440px] transform
                                        transition duration-1000
                                        hover:scale-95">
            <p class="text-brand-orange font-medium text-2xl md:text-4xl mb-4">Mini-airport Installation</p>
            <p class="mb-5 max-w-lg text-brand-gray-secondary2 text-xs md:text-lg leading-6">
              Take your logistics to new heights with our mini-airport installation service,
              offering a secure and sophisticated hub for your unmanned aerial vehicles.
            </p>
            <button @click="goTo('solutions', 'mini')" class="text-brand-orange text-xs md:text-base rounded-3xl hover:bg-brand-orange hover:text-white border border-brand-orange px-6 py-2">
              Read more
            </button>

            <img src="../assets/r4.svg" alt="r1" class="absolute right-0 bottom-0 -z-10">
          </div>
        </div>
      </div>
    </div>

    <!-- Industry Experience -->
    <div class="xl:max-w-[1320px] 2xl:max-w-[1380px] mx-auto px-4 py-20">
      <p class="uppercase text-brand-gray-secondary3 text-sm md:text-xl font-medium mb-16 tracking-widest">Industry Experience</p>

      <div class="flex items-start gap-8">
        <div class="md:w-1/2">
          <p class="font-medium text-3xl md:text-5xl text-brand-gray-secondary4 mb-10">Expanding
            <span class="bg-gradient-to-r from-[#66D3C0] via-[#6ECF22] to-[#EDFA58] bg-clip-text text-transparent">
              delivery horizons
            </span>
          </p>

          <p class="md:text-lg mb-8">
            ARC is committed to breaking barriers and creating connections. We serve those often overlooked by traditional delivery methods, bringing essential goods and services to communities that need them the most. Every flight we undertake is a step towards a more connected, accessible, and greener world.
          </p>

          <button @click="goTo('solutions', 'solutions')" class="hidden md:block bg-brand-green px-6 py-2 font-medium text-white rounded-3xl">Read more</button>
        </div>

        <div class="hidden md:block relative w-1/2">
          <img src="../assets/1drone1.png" alt="drone"/>
        </div>
      </div>
    </div>

    <!-- Features -->
    <div class="xl:max-w-[1320px] 2xl:max-w-[1380px] mx-auto px-4 flex flex-col md:flex-row md:items-center md:justify-between gap-4">
      <div class="md:w-96 border-b md:border-none py-6">
        <img src="../assets/f1.svg" alt="f1" class="mb-8"/>
        <p class="text-3xl mb-2">Enterprises</p>
        <p>
          We develop courier drones for delivering food, medication, and parcels.
        </p>
      </div>

      <div class="md:w-96 border-b md:border-none py-6">
        <img src="../assets/f2.svg" alt="f1" class="mb-8"/>
        <p class="text-3xl mb-2">Businesses</p>
        <p>
          We develop courier drones for delivering food, medication, and parcels.
        </p>
      </div>

      <div class="md:w-96 border-b md:border-none py-6">
        <img src="../assets/f3.svg" alt="f1" class="mb-8"/>
        <p class="text-3xl mb-2">Governments</p>
        <p>
          We develop courier drones for delivering food, medication, and parcels.
        </p>
      </div>

      <div class="md:w-96 border-b md:border-none py-6">
        <img src="../assets/f4.svg" alt="f1" class="mb-8"/>
        <p class="text-3xl mb-2">Home Delivery</p>
        <p>
          We develop courier drones for delivering food, medication, and parcels.
        </p>
      </div>
    </div>

    <!-- Features2 -->
    <div class="max-w-7xl mx-auto px-4 py-24 flex items-center justify-around gap-4 overflow-x-auto">
      <div class="text-center">
        <div class="w-40 h-40 bg-i1 hover:bg-i1_ bg-cover bg-center mb-2"></div>
        <p>Postal</p>
      </div>
      <div class="text-center">
        <div class="w-40 h-40 bg-i2 hover:bg-i2_ bg-cover bg-center mb-2"></div>
        <p>Healthcare</p>
      </div>
      <div class="text-center">
        <div class="w-40 h-40 bg-i3 hover:bg-i3_ bg-cover bg-center mb-2"></div>
        <p>Logistics & Warehouse</p>
      </div>
      <div class="text-center">
        <div class="w-40 h-40 bg-i4 hover:bg-i4_ bg-cover bg-center mb-2"></div>
        <p>Courier</p>
      </div>
      <div class="text-center">
        <div class="w-40 h-40 bg-i5 hover:bg-i5_ bg-cover bg-center mb-2"></div>
        <p>Retail</p>
      </div>
    </div>

    <div class="md:hidden w-full flex justify-center mt-12">
      <button class="bg-brand-green px-6 py-2 font-medium text-white rounded-3xl text-center">Read more</button>
    </div>

    <!-- Bg2 -->
    <div class="h-screen max-h-[880px] w-full my-24 bg-fixed bg-bg2 bg-cover">
      <div class="h-full w-full flex justify-center items-center bg-opacity-30 bg-black relative">
        <p class="text-3xl md:text-6xl text-white w-3/4 text-center">
          We are building <span class="text-brand-green">an autonomous delivery network</span> that will be fast, eco-friendly, accessible, and efficient
        </p>
      </div>
    </div>

    <!-- Convenient Delivery -->
    <div class="max-w-[1044px] w-full mx-auto px-4 py-12 md:py-24">
      <div class="mb-24">
        <h3 class="text-center text-3xl w-64 md:w-full mx-auto font-medium md:text-6xl mb-6">All for convenient delivery</h3>
        <p class="md:text-lg text-center max-w-lg mx-auto">Revolutionize your delivery expectations with our drone service, delivering at unmatched speeds for your convenience</p>
      </div>

      <div class="flex flex-col md:flex-row w-full gap-8 md:justify-between md:items-center relative mb-40">
        <div class="w-64 md:max-w-[412px] px-6 md:px-0 md:w-full relative order-last md:order-first">
          <img src="../assets/d1.png" class="w-64 md:max-w-[412px] md:w-full"/>
        </div>

        <div class="bg-brand-green absolute top-0 md:left-[470px] w-0.5 h-[800px]"></div>

        <div class="md:max-w-[480px] w-full relative px-6 md:px-0">
          <p class="text-brand-green font-medium mb-1">Delivery</p>
          <p class="text-2xl md:text-3xl font-medium mb-4">Faster Delivery Times</p>
          <p class="text-brand-gray-secondary7 md:text-xl">Drone delivery can offer significantly faster delivery times
            compared to traditional ground or air delivery methods. This helps meet growing consumer demand for quick order fulfillment.
          </p>
        </div>
      </div>

      <div class="flex flex-col md:flex-row w-full gap-8 md:justify-between md:items-center relative pb-24">
        <div class="w-64 md:max-w-[412px] px-6 md:px-0 md:w-full relative order-last md:order-first">
          <img src="../assets/d2.png" class="w-64 md:max-w-[412px] md:w-full"/>
        </div>

        <div class="bg-brand-gray-secondary6 absolute top-0 md:left-[470px] w-0.5 h-[800px]"></div>

        <div class="md:max-w-[480px] w-full relative px-6 md:px-0">
          <p class="text-brand-green font-medium mb-1">Capacity</p>
          <p class="text-2xl md:text-3xl font-medium mb-4">Large Capacity</p>
          <p class="text-brand-gray-secondary7 md:text-xl">
            Our drones are designed with large cargo holds, allowing them to carry substantial loads efficiently. This means fewer trips and faster deliveries for bulk orders.
          </p>
        </div>
      </div>

      <div class="flex flex-col md:flex-row w-full gap-8 md:justify-between md:items-center relative pb-24">
        <div class="w-64 md:max-w-[412px] px-6 md:px-0 md:w-full relative order-last md:order-first">
          <img src="../assets/d3.png" class="w-64 md:max-w-[412px] md:w-full"/>
        </div>

        <div class="bg-brand-gray-secondary6 absolute top-0 md:left-[470px] w-0.5 h-[800px]"></div>

        <div class="md:max-w-[480px] w-full relative px-6 md:px-0">
          <p class="text-brand-green font-medium mb-1">Reliance</p>
          <p class="text-2xl md:text-3xl font-medium mb-4">Low Labor Reliance</p>
          <p class="text-brand-gray-secondary7 md:text-xl">
            By automating the delivery process, our drones significantly reduce the need for human labor. This minimizes costs and operational complexities, ensuring a seamless delivery experience.
          </p>
        </div>
      </div>

      <div class="flex flex-col md:flex-row w-full gap-8 md:justify-between md:items-center relative pb-24">
        <div class="w-64 md:max-w-[412px] px-6 md:px-0 md:w-full relative order-last md:order-first">
          <img src="../assets/d4.png" class="w-64 md:max-w-[412px] md:w-full"/>
        </div>

        <div class="bg-brand-gray-secondary6 absolute top-0 md:left-[470px] w-0.5 h-[800px]"></div>

        <div class="md:max-w-[480px] w-full relative px-6 md:px-0">
          <p class="text-brand-green font-medium mb-1">Eco-Friendly</p>
          <p class="text-2xl md:text-3xl font-medium mb-4">Low Emission</p>
          <p class="text-brand-gray-secondary7 md:text-xl">
            Our drones are powered by electric motors, producing minimal emissions compared to traditional delivery vehicles. This environmentally friendly approach helps reduce our carbon footprint and promotes sustainable delivery practices.
          </p>
        </div>
      </div>

      <div class="flex flex-col md:flex-row w-full gap-8 md:justify-between md:items-center relative pb-24">
        <div class="w-64 md:max-w-[412px] px-6 md:px-0 md:w-full relative order-last md:order-first">
          <img src="../assets/d5.png" class="w-64 md:max-w-[412px] md:w-full"/>
        </div>

        <div class="bg-brand-gray-secondary6 absolute top-0 md:left-[470px] w-0.5 h-[800px]"></div>

        <div class="md:max-w-[480px] w-full relative px-6 md:px-0">
          <p class="text-brand-green font-medium mb-1">Intelligent flight</p>
          <p class="text-2xl md:text-3xl font-medium mb-4">Obstacle Avoidance</p>
          <p class="text-brand-gray-secondary7 md:text-xl">
            Equipped with advanced sensors and AI, our drones can detect and navigate around obstacles in real-time. This capability ensures safe and reliable deliveries, even in complex environments.
          </p>
        </div>
      </div>

      <div class="flex flex-col md:flex-row w-full gap-8 md:justify-between md:items-center relative pb-24">
        <div class="w-64 md:max-w-[412px] px-6 md:px-0 md:w-full relative order-last md:order-first">
          <img src="../assets/d6.png" class="w-64 md:max-w-[412px] md:w-full"/>
        </div>

        <div class="bg-brand-gray-secondary6 absolute top-0 md:left-[470px] w-0.5 h-[800px]"></div>

        <div class="md:max-w-[480px] w-full relative px-6 md:px-0">
          <p class="text-brand-green font-medium mb-1">Tracking</p>
          <p class="text-2xl md:text-3xl font-medium mb-4">Real-time Tracking</p>
          <p class="text-brand-gray-secondary7 md:text-xl">
            With integrated GPS and advanced tracking systems, customers can monitor their deliveries in real-time. This transparency enhances trust and allows for precise delivery time estimations
          </p>
        </div>
      </div>

      <div class="flex flex-col md:flex-row w-full gap-8 md:justify-between md:items-center relative pb-24">
        <div class="w-64 md:max-w-[412px] px-6 md:px-0 md:w-full relative order-last md:order-first">
          <img src="../assets/d7.png" class="w-64 md:max-w-[412px] md:w-full"/>
        </div>

        <div class="bg-brand-gray-secondary6 absolute top-0 md:left-[470px] w-0.5 h-[800px]"></div>

        <div class="md:max-w-[480px] w-full relative px-6 md:px-0">
          <p class="text-brand-green font-medium mb-1">Less expensive</p>
          <p class="text-2xl md:text-3xl font-medium mb-4">Cost Effective</p>
          <p class="text-brand-gray-secondary7 md:text-xl">
            Utilizing drones for delivery reduces expenses related to fuel, labor, and maintenance associated with traditional delivery methods. This cost efficiency translates to savings for both businesses and customers.
          </p>
        </div>
      </div>
    </div>

    <!-- Bg3 -->
    <div class="h-screen max-h-[900px] bg-bg3 bg-cover bg-center bg-fixed relative rounded-3xl mb-24">
      <div class="w-full h-full p-4 md:p-12 flex flex-col justify-between text-white bg-black bg-opacity-50 rounded-3xl">
        <img src="../assets/logo-white.svg" class="w-32"/>

        <div>
          <p class="text-sm md:text-lg uppercase tracking-widest mb-3">About company</p>
          <p class="text-2xl md:text-5xl max-w-3xl mb-8 tracking-wide leading-loose">
            We specialize in providing comprehensive solutions that revolutionize the way we approach logistics and transportation.
          </p>
          <button @click="goTo('company')" class="bg-brand-green text-sm md:text-base px-6 py-3 font-medium text-white rounded-3xl">
            About our company
          </button>
        </div>

        <div class="grid grid-cols-2 md:grid-cols-3 gap-4 items-center">
          <div class="md:border-t-2 py-10">
            <p class="text-4xl md:text-6xl mb-1">
              98%
            </p>
            <p class="font-medium">Percentage of on-time deliveries</p>
          </div>

          <div class="md:border-t-2 py-10">
            <p class="text-4xl md:text-6xl mb-1">
              20%
            </p>
            <p class="font-medium">Average cost savings for customers</p>
          </div>

          <div class="md:border-t-2 py-10">
            <p class="text-4xl md:text-6xl mb-1">
              1.25 million
            </p>
            <p class="font-medium">Total distance covered</p>
          </div>
        </div>
      </div>
    </div>

    <!-- Last -->
    <div class="xl:max-w-[1320px] 2xl:max-w-[1380px] mx-auto px-4 py-24 flex flex-col items-center justify-center bg-[#F4FAF4] rounded-3xl">
      <img src="../assets/trees.png" class="md:max-w-[625px] mb-2"/>
      <p class="text-2xl md:text-4xl mb-8">Fast, Reliable, Eco-Friendly</p>
      <div class="mb-3 max-w-lg w-full">
        <div class="relative flex w-full mb-4">
          <button
              class="!absolute right-1 top-1 z-10 select-none
              rounded bg-brand-green h-14 px-10 text-center align-middle text-white
              shadow-md shadow-brand-green-500/20 transition-all hover:shadow-lg hover:brand-green-500/40
              focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none
              peer-placeholder-shown:pointer-events-none peer-placeholder-shown:bg-blue-gray-500
              peer-placeholder-shown:opacity-50 peer-placeholder-shown:shadow-none"
              type="button"
              data-ripple-light="true"
          >
            Get started
          </button>
          <input
              type="email"
              class="peer bg-white h-16 w-full rounded-[7px] border border-blue-gray-200
              bg-transparent px-8 py-2.5 pr-20 font-sans text-sm font-normal text-blue-gray-700
              outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200
              placeholder-shown:border-t-blue-gray-200 focus:border-2 focus:border-brand-green
              focus:outline-0 disabled:border-0 disabled:bg-blue-gray-50"
              placeholder="Enter your work email"
              required
          />
        </div>
      </div>
      <p class="text-center md:text-left text-brand-gray-secondary7">Join our waitlist, discover the full benefits of ARC delivery.</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HomeView',
  data: () => ({
    currentSlide: 1,
    slides: [
      {
        id: 1,
        url: 'products-airis',
        isVisible: true,
        image: 'drone2.png',
        name: 'Platform Airis',
        description: '<p class="text-4xl md:text-7xl text-[#B4B4B4] mb-5">Platform <span class="text-brand-green">Airis</span></p>\n' +
            '          <p class="text-brand-gray-secondary5 max-w-2xl px-4 mx-auto md:text-lg mb-10">\n' +
            '            Introducing our <span class="text-brand-black">Platform Airis</span> model: boasting a substantial payload capacity and a short-to-medium flight range,\n' +
            '            it\'s ideal for <span class="text-brand-green">businesses, clinics, and home deliveries.</span>\n' +
            '          </p>',
        stat: {
          distance: 25,
          weight: 10,
          speed: 20,
          time: 45,
          timeM: 'm'
        }
      },
      {
        id: 2,
        url: 'products-airis-lite',
        isVisible: false,
        image: '1drone1.png',
        name: 'Platform Airis Flite',
        description: '<p class="text-4xl md:text-7xl text-[#B4B4B4] mb-5">Platform <span class="text-brand-green">Airis Flite</span></p>\n' +
            '          <p class="text-brand-gray-secondary5 max-w-2xl px-4 mx-auto md:text-lg mb-10">\n' +
            '            Introducing our <span class="text-brand-black">Platform Airis</span> model: boasting a substantial payload capacity and a short-to-medium flight range,\n' +
            '            it\'s ideal for <span class="text-brand-green">businesses, clinics, and home deliveries.</span>\n' +
            '          </p>',
        stat: {
          distance: 450,
          weight: 10,
          speed: 30,
          time: 4,
          timeM: 'h'
        }
      },
      /*{
        id: 3,
        url: 'products-airis-lite',
        isVisible: false,
        image: 'drone2.png',
        name: 'Platform Airis 3',
        description: '<p class="text-4xl md:text-7xl text-[#B4B4B4] mb-5">Platform <span class="text-brand-green">Airis 3</span></p>\n' +
            '          <p class="text-brand-gray-secondary5 max-w-2xl px-4 mx-auto md:text-lg mb-10">\n' +
            '            Introducing our <span class="text-brand-black">Platform Airis</span> model: boasting a substantial payload capacity and a short-to-medium flight range,\n' +
            '            it\'s ideal for <span class="text-brand-green">businesses, clinics, and home deliveries.</span>\n' +
            '          </p>',
        stat: {
          distance: 25,
          weight: 10,
          speed: 20,
          time: 45
        }
      }*/
    ]
  }),
  methods: {
    openContactForm() {
      this.$store.dispatch('showContactForm', true)
    },
    moveSlide(id) {
      this.currentSlide = id

      let active = document.querySelector('.ss.translate-x-0')
      let activeId = parseInt(active.id)
      let all = document.querySelectorAll('.ss')

      let diff = id - activeId

      if (diff >= 0) {
        for (let i = activeId; i <= id; i++) {
          if (i === id) {
            all[i - 1].classList.remove('translate-x-full')
            all[i - 1].classList.add('translate-x-0')
          }else {
            all[i - 1].classList.remove('translate-x-0')
            all[i - 1].classList.add('-translate-x-full')
          }
        }
      }else {
        for (let i = activeId; i >= id; i--) {
          if (i === id) {
            all[i - 1].classList.remove('translate-x-full')
            all[i - 1].classList.remove('-translate-x-full')
            all[i - 1].classList.add('translate-x-0')
          }else {
            all[i - 1].classList.remove('translate-x-0')
            all[i - 1].classList.remove('-translate-x-full')
            all[i - 1].classList.add('translate-x-full')
          }
        }
      }
    },
    goTo(name, hash = null) {
      this.showMenu = false
      if (hash) {
        this.$router.push({name: name, hash: '#' + hash})
      }else {
        this.$router.push({name: name})
      }
    },
  }
}
</script>

<style>
.no-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}
</style>
